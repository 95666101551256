.hide-pdf-div {
  display: block;
}
.show-pdf-div {
  display: none;
}

@media screen and (max-width: 576px) {
  .hide-pdf-div {
    display: none;
  }
  .show-pdf-div {
    display: block;
  }
}
