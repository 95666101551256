/* .chip-custom-parent{
    background-color: aquamarine;
    position: relative;
} */
.chip-custom{
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 15%;
    border: 1px solid #fff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: lighter;
}