.togglebtn {
  position: absolute;
  top: 8%;
  left: 100%;
  transform: translate(-50%, -50%);
  /* border-radius: 50%; */
  height: 25px;
  width: 25px;
  opacity: 0;
  transition: all 0.3s linear;
  cursor: pointer;
  border-radius: 5px;
  font-size: 10px;
  background-color: white;
  /* border: 1px solid gray; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.togglebtn-parent:hover .togglebtn {
  opacity: 1;
}
.togglebtn:hover {
  background-color: #2c7be5;
  color: white;
}

.element {
    align-items: center;
    background: linear-gradient(-45deg, rgba(0,0,0,0.22), rgba(255,255,255,0.25));
    box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
     -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    display: flex;
    height: 200px;
    justify-content: center;
    width: 200px;
  }
.bk-to-prts:hover{
  color: #2c7be5;
}
.show-transition{
  transition: all 0.3s linear;
}
.propertyNameCustom{
  /* background-color: aqua; */
  /* padding: 5px; */
  /* margin-left: -10px; */
}

