.bg-name {
  /* background-color: rgb(0, 200, 0); */
  border-radius: 50% !important;
  height: 35px;
  width: 35px;
  position: relative;
}
.bg-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: white; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
