#msdoc-iframe {
  height: 600px;
}
/* .btn-div-mine-no-mobile {
  display: block;
} */
/* .btn-div-mine-mobile {
  display: none;
  left: 80%;


} */
/* #myFullScreenDiv ::-webkit-scrollbar {display:none;} */
@media only screen and (max-width: 600px) {
  /* .btn-div-mine-no-mobile {
    display: none;
  } */
  .btn-div-mine-mobile {
    /* display: block; */
  left: 80%

  }
}
