.header-row {
  width: 70%;
}
.header-br-row {
  display: none;
}
@media only screen and (max-width: 600px) {
  /* ...your css here and you're good to go! */
  .header-row {
    width: 100%;
  }
  .header-br-row {
    margin-top: 10px;
    display: block;
  }
}
