.imgs {
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.img-base {
  /* background-color: blueviolet; */
  position: relative;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.24) 0px 5px 7px; */
}
.img-over-1 {
  /* background-color: bisque; */
  position: absolute;
  top: 5px;
  left: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.24) 0px 5px 7px; */
}
.img-over-2 {
  /* background-color: bisque; */
  position: absolute;
  top: 10px;
  left: 20px;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.24) 0px 5px 7px; */
}
.img-over-3 {
  /* background-color: bisque; */
  position: absolute;
  top: 15px;
  left: 30px;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px, rgba(0, 0, 0, 0.24) 0px 5px 7px; */
}
.img-over-4 {
  /* background-color: bisque; */
  position: absolute;
  top: 20px;
  left: 40px;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
}
.img-over-5 {
  /* background-color: bisque; */
  position: absolute;
  top: 25px;
  left: 50px;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
}
.img-over-6 {
  /* background-color: bisque; */
  position: absolute;
  top: 30px;
  left: 60px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.img-over-7 {
  /* background-color: bisque; */
  position: absolute;
  top: 35px;
  left: 70px;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
}

.img-single {
  transform: perspective(800px) rotateY(20deg);
}
