@media screen and (max-width: 576px) {
  .custom-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .md-mt{
    margin-top: 10px;
  }
}
