.visitor-mgmt-start-timer .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.visitor-mgmt-start-timer > .MuiInputBase-root {
  border: 1px solid #d8e2ef !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  font-family: "Poppins" !important;
  background-color: #fff !important;
}

.visitor-mgmt-start-timer .MuiInputBase-input {
  padding: 0.3925rem 1rem !important;
}

.visitor-mgmt-end-timer .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.visitor-mgmt-end-timer > .MuiInputBase-root {
  border: 1px solid #d8e2ef !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  font-family: "Poppins" !important;
  background-color: #fff !important;
}

.visitor-mgmt-end-timer .MuiInputBase-input {
  padding: 0.3925rem 1rem !important;
}

.MuiPickersLayout-root ul {
  width: 100px !important;
}
