.custom-img-box {
  height: 300px;
  background-color: silver;
  border-radius: 5px;
  outline: 5px dotted gray;
  outline-offset: -20px;
  position: relative;
}
.inner-text-img-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: aliceblue;
}

/*  */
#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.bgc-blur {
  backdrop-filter: blur(10px) !important;
  background-color: #cbd5e1 !important;
}
.my-dropzone1 {
  background-color: rgb(237, 237, 237);
  height: 100px;
  border: 3px dotted silver;
  border-radius: 10px;
  position: relative;
  width: 150px;
}
.my-dropzone-text1 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}
.my-dropzone-closebtn1 {
  position: absolute;
  top: 20%;
  left: 80%;
  transform: translate(-50%, -50%);
  background-color: whitesmoke !important;
  border-radius: 50% !important;
  /* height: 25px !important; */
  width: 10px !important;
  font-size: 0.8rem !important;
  cursor: pointer;
  /* box-shadow: 3px 3px 4px 5px black inset; */
}
.img-backdrop{
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}