.visitor-parking-report-div {
  display: block;
}
.visitor-parking-report-div-mobile {
  display: none;
}

@media screen and (max-width: 576px) {
  .visitor-parking-report-div {
    display: none;
  }
  .visitor-parking-report-div-mobile {
    display: block;
  }
}
