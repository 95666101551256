.responsive {
  width: 100% !important;
  min-width: 530px !important;
  min-height: 100vh !important;
}

@media (max-width: 575px) {
  .resSpan {
    display: block !important;
    /* background-color: red; */
  }
}
